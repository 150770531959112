// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDKsZhDvyknMl1WeStu_tD8rqzKtp9cWrs",
  authDomain: "ldko-2f3f9.firebaseapp.com",
  databaseURL: "https://ldko-2f3f9-default-rtdb.firebaseio.com",
  projectId: "ldko-2f3f9",
  storageBucket: "ldko-2f3f9.appspot.com",
  messagingSenderId: "8111835285",
  appId: "1:8111835285:web:08cf58dd8545bf151db39c",
  measurementId: "G-59FXL0NCL9"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };