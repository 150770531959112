import React from 'react';
import headimg from './AxisMain.jpg';
function Head() {
  



  return (
    <>
      
      <div className="logo-container">
          <img
            src={headimg}
            alt="Logo"
            style={{
              height: '63px',
              marginTop: '0px',
            }}
          />
        </div>

    </>
  );
}



export default Head;
