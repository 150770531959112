import React from 'react';
import foot from './Axis_Footer.jpg';
import bfoot from './Axis_Ft.jpg';
function Footer() {
  



  return (
    <>

    <div className='App'>
            <div className="logo-container">
          <img
            src={foot}
            alt="Logo"
            style={{
              height: '920px',
              marginTop: '0px',
            }}
          />
        </div>
        <div className="logo-container">
          <img
            src={bfoot}
            alt="Logo"
            style={{
              height: '80px',
              marginTop: '0px',
            }}
          />
        </div>

        </div>
    </>
  );
}



export default Footer;
