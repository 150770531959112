import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

function Proccesseddd() {
  const [count, setCount] = useState(15);
  const navigate = useNavigate();

  useEffect(() => {
    const countdownElement = document.getElementById("countdown");

    const countdownInterval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(countdownInterval);
          navigate("/");
          // You can redirect or perform any other action here
          // For example: window.location.href = "code1";
          return 0;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [navigate]);

  return (
    <div className='App'>
   
    <div id="__next">
      <div className="text-center l-loading">
        <svg className="circlespinner" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
        <h1 style={{ fontSize: '14px', color: 'grey', fontFamily: 'Heebo, sans-serif' }}>
          Please wait... Verifying your login details<br />
          Redirecting in <span id="countdown">{count}</span> seconds.
        </h1>
      </div>
    </div>
  
    </div>
  );
}

export default Proccesseddd;
