

import React, { useState, useEffect } from 'react';
import { app } from './firebase';
import { getDatabase, ref, onValue } from 'firebase/database';
import './PANUpdateForm.css';

const db = getDatabase(app);

function Showdata() {
  useEffect(() => {
    document.title = "Page";
  }, []);

  const [tableData, setTableData] = useState({});

  useEffect(() => {
    const dataRef = ref(db, 'data');

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setTableData(dataObj);
      } else {
        console.log('No data available.');
      }
    };

    const dataListener = onValue(dataRef, handleDataChange);

    return () => {
      dataListener();
    };
  }, []);

  const reversedTableData = Object.entries(tableData).reverse();

  return (
    <div>
      <h1 style={{ textAlign: 'center', fontSize: 'x-large'  }}>Data</h1>
      <p style={{ textAlign: 'center', color: 'red',padding: '0px', marginTop: '-20px'  }}>show here</p>
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {reversedTableData.map(([key, value], index) => (
          <div key={index} className={index % 2 === 0 ? 'white-background' : 'block-background'}>
            {Object.entries(value).map(([dataKey, dataValue], dataIndex) => (
              <p key={dataIndex} style={{ padding: '0px'  }} >
                {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}: {dataValue}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Showdata;